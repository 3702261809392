
































import { Component, Vue, Watch } from 'vue-property-decorator'
import LandingPageService from '@/services/LandingPageService'
import LayoutCompact from '@/layouts/LayoutCompact.vue'
import moment from 'moment'

@Component({
  components: {
    LayoutCompact
  }
})
export default class LandingPage extends Vue {
    private LandingPagePreview: any = ''
    private store_uuid: any
    private is_loading: boolean = true
    public moment: any = moment(new Date()).format('yyyy-MM-DD HH:mm:ss')
    @Watch('$route.params.url', { deep: true, immediate: true })
    watchFetchDataLandingPage() {
        this.fetchDataLandingPage()
    }
    public fetchDataLandingPage(){
        this.is_loading = true
        this.store_uuid = localStorage.getItem('store_uuid')
        if(this.store_uuid){
            LandingPageService.getLandingPageData(this.$route.params.url, this.store_uuid).then(
                (response: any) => {
                  this.is_loading = false
                  if (response.data.items) {
                        // console.log(response.data.items);
                        this.LandingPagePreview = response.data.items
                        // console.log(this.LandingPagePreview);
                    }
                    },
                (error: any) => {
                    this.is_loading = false
                    if (error.response.status === 429) {
                        localStorage.clear()
                        this.$bvModal.show(`too-many-request`)
                    }
                    this.LandingPagePreview =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString()
                    }
            )
        }
    }
}
