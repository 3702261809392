import Const from '../constants'
import axiosApiInstance from '../interceptors'

class LandingPageService {
  getLandingPageData(url: string, store_uuid: any) {
    return axiosApiInstance.get(Const.api_url + 'store/' + store_uuid + '/url/' + url);
  }
}

export default new LandingPageService()
